var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-toolbar',{staticClass:"rounded",attrs:{"dense":"","height":"$vuetify.breakpoint.xsOnly ? '96px' : null"}},[_c('v-row',{staticStyle:{"width":"100%"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","lg":"2"}},[_c('v-select',{staticClass:"mt-1 no-border-input",staticStyle:{"max-width":"140px"},attrs:{"items":_vm.years,"item-text":"name","item-value":"id","prefix":_vm.L('Anio'),"small-chips":"","dense":"","hide-details":""},on:{"change":_vm.loadData},model:{value:(_vm.pagerequest.year),callback:function ($$v) {_vm.$set(_vm.pagerequest, "year", $$v)},expression:"pagerequest.year"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","lg":"2"}},[_c('cus-date-picker',{ref:"dateFrom",attrs:{"item":_vm.dateFrom},model:{value:(_vm.pagerequest.date_from),callback:function ($$v) {_vm.$set(_vm.pagerequest, "date_from", $$v)},expression:"pagerequest.date_from"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"2","lg":"2"}},[_c('cus-date-picker',{ref:"dateTo",attrs:{"item":_vm.dateTo},model:{value:(_vm.pagerequest.date_to),callback:function ($$v) {_vm.$set(_vm.pagerequest, "date_to", $$v)},expression:"pagerequest.date_to"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"4","lg":"4"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6","sm":"6","md":"2","lg":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0",staticStyle:{"min-width":"30px"},attrs:{"small":"","icon":"","loading":_vm.loading},on:{"click":_vm.loadData}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Actualizar')))])])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-item-group',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.widgets),function(item,index){return _c('v-col',{key:index},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-auto",attrs:{"color":active ? 'grey lighten-3' : 'white',"max-width":"400"},on:{"click":toggle}},[_c('v-card-title',{staticClass:"align-start py-0"},[_c('v-sheet',{staticClass:"overflow-hidden transition-swing v-card--material__sheet mt-3",attrs:{"color":_vm.loading ? 'red lighten-2' : 'primary',"elevation":"6","max-width":"100%","rounded":""}},[_c('div',{staticClass:"pa-4"},[_c('v-icon',{attrs:{"color":"white","large":""},on:{"click":_vm.loadWidgets}},[_vm._v(_vm._s(item.icon))])],1)]),_c('div',{staticClass:"wid-content font-weight-light v-card--material__title pl-3 py-2"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('small',{staticClass:"font-weight-regular subtitle"},[_vm._v(_vm._s(item.desc))]),_c('h3',{staticClass:"font-weight-light success--text"},[_vm._v(" "+_vm._s(item.value)+" ")])])],1)],1)]}}],null,true)})],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-widget',{staticClass:"cashier-left",attrs:{"title":_vm.title,"icon":"dashboard","simpleToolbar":true}},[_c('div',{attrs:{"slot":"widget-header-action"},slot:"widget-header-action"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"px-0",staticStyle:{"min-width":"30px"},attrs:{"small":"","icon":"","loading":_vm.loading},on:{"click":_vm.loadCharts}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Actualizar')))])])],1),_c('div',{attrs:{"slot":"widget-content"},slot:"widget-content"},[_c('apexcharts',{attrs:{"type":"area","width":"100%","height":"350","options":_vm.options,"series":_vm.series}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }